<template>
	<!-- Add Voter Modal -->
		<div class="modal fade show" id="modal-default" tabindex="-1" style="display: block;" aria-modal="true">
			<div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
				<div class='modal-content' v-if='add_running'>
					<div class="modal-body">
						<h5 class='text-info'>Adding Item</h5>
						Adding item to ticket...
					</div>
				</div>
				<div class="modal-content" v-if='!add_running'>
					<div class="modal-header">
						<h5 class="modal-title">Find Inventory Item</h5><i class="modal-close zwicon-close pull-right display-4" @click='close'></i>
					</div>
					<div class="modal-body">
						<table id="data-table" class="table dataTable no-footer" role="grid" aria-describedby="data-table_info">
                            <tbody class="table-dark">
								<tr role="row">
                                    <th colspan="1"><label for="temp-item-category">Serial Number</label></th>
                                    <td colspan="3">
                                        <div class="form-group mb-0">
                                            <input id="search-item-search" class="form-control" v-model='filter.search'>
                                        </div>
                                    </td>
                                </tr>
								<tr role="row">
                                    <th colspan="1"><label for="temp-item-category">Company</label></th>
                                    <td colspan="3">
                                        <div class="form-group mb-0">
                                            <select id="temp-item-company" class="form-control" v-model='filter.company_id'>
                                                <option value="any" selected>-</option>
                                                <option v-for="company in company_list.list" v-bind:key="company" :value="company.company_id">{{company.name}}</option>
                                            </select>
                                        </div>
                                    </td>
                                </tr>
                                <tr role="row">
                                    <th colspan="1"><label for="temp-item-category">Category</label></th>
                                    <td colspan="3">
                                        <div class="form-group mb-0">
                                            <select id="temp-item-category" class="form-control" v-model='filter.category_id'>
                                                <option value="">-</option>
                                                <option v-for="category in category_list.list" v-bind:key="category" :value="category.configuration_id">{{category.name}}</option>
                                            </select>
                                        </div>
                                    </td>
                                </tr>
								<tr role="row">
                                    <th colspan="1"><label for="temp-item-serialno">Description</label></th>
                                    <td colspan="3"> 
                                        <div class="form-group mb-0">
                                            <select id="temp-item-description" class="form-control" v-model='filter.description_id'>
                                                <option value="">-</option>
                                                <option v-for="description in description_list.list" v-bind:key="description" :value="description.configuration_id">{{description.name}}</option>
                                            </select>
                                        </div>
                                    </td>
                                </tr>
								<tr role="row">
                                    <td colspan="4"> 
                                        <div class="form-group mb-0">
                                            <button type="button" class="btn btn-primary float-right" @click='action'>Search</button>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
						<table class="table table-sm mb-0" v-if='filtered_list !== undefined'>
							<thead class="thead-dark">
								<tr>
									<th>Serial No</th>
									<th>Category</th>
									<th>Description</th>
									<th></th>
								</tr>
							</thead>
							<tbody>
								<tr v-for='item in inventory_list.list' v-bind:key="item.inventory_id">
									<td ><i v-if="!item.in" alt="Out" class="zwicon-export text-warning"></i>{{item.serial_no}}</td>
									<td>{{category_name(item.category)}}</td>
									<td>{{description_name(item.description)}}</td>
									<td style='text-align: right; position: relative;'>
										<button class="btn btn-primary btn-sm" v-tooltip="'Select'" @click='add_item(item)'><i class="zwicon-plus"></i> Add Item</button>
									</td>
								</tr>
								<tr v-if='inventory_list.list !== undefined && inventory_list.list.length == 0'><td COLSPAN='4'>No items were found that match your current filters.</td></tr>
							</tbody>
						</table>
					</div>
					<ErrorMessage :message='error' v-if='error.length > 0' />
				</div>
			</div>
		</div>
		<!-- End Add Voter Modal -->
</template>

<script>

	import PermissionService from "@/common/permission.service";
	import { mapGetters } from "vuex";
	import store from "@/store";
	import router from "@/router";

	import ErrorMessage from "@/components/General/ErrorMessage";
	import { LOAD_COMPANY_LIST } from "@/store/actions.type";
	import { LOAD_INVENTORY_LIST } from "../../../store/actions.type";
	import { SET_INVENTORY_LIST } from "../../../store/mutations.type";

	export default {
		name: 'AddInventoryItem',
		components: {
			ErrorMessage
		},
		props: {
			existing: {
				type: Array
			}
		},
		data(){
			return {
				error: '',
				add_running: false,
				filter: {
					search: '',
					category_id: '',
					description_id: '',
					company_id: 'any',
					size_id: '',
					per_page: 10
				},
				errors: {
					name: '',
					comments: '',
					description: '',
					serial: ''
				}
			};
		},
		beforeMount() {
			store.commit(SET_INVENTORY_LIST,{});
			this.load_company_list(function(){
                
			}, function(){
                
				router.replace({ name: 'inventory', params: { error: "An error occured while attempting to load company list." }});
			});
		},
		computed: {
			filtered_list(){
				return this.inventory_list.list;
				//return this.inventory_list.list.filter(x => !this.existing.includes(x.inventory_id) );
			},
			...mapGetters(['company_list', 'user', 'loading','inventory_list','size_list','category_list','connector_list','description_list'])
		},
		methods: {
			description_name(desc_id){
                if(this.description_list.list === undefined) return "";
                let item = this.description_list.list.filter(x => x.configuration_id == desc_id);
                if(item.length == 1) return item[0].name;
                else return '???';
            },
            category_name(desc_id){
                if(this.category_list.list === undefined) return "";
                let item = this.category_list.list.filter(x => x.configuration_id == desc_id);
                if(item.length == 1) return item[0].name;
                else return '???';
            },
            size_name(desc_id){
                if(this.size_list.list === undefined) return "";
                let item = this.size_list.list.filter(x => x.configuration_id == desc_id);
                if(item.length == 1) return item[0].name;
                else return '???';
            },
			getPermission(category, permission){
				return PermissionService.getPermission(category, permission);
			},
			get_error(key){
				if(this.errors[key] !== undefined) return this.errors[key];
				return '';
			},
			close(){
				if(this.add_running) return;
				this.$emit('close');
			},
			action(){
				this.search_inventory_list();
				
			},
			search_inventory_list(success_fn, failure_fn = () => {}){
				this.list_loading = true;
				this.filter_error = "";
				
				store.dispatch(LOAD_INVENTORY_LIST, this.filter).then(data => {
					success_fn(data);
				}).catch(message => {
					failure_fn(message);
				}).finally(() => {
					this.list_loading = false;
				});
			},
			load_company_list(success_fn, failure_fn = () => {}){
				this.list_loading = true;
				this.filter_error = "";
				
				store.dispatch(LOAD_COMPANY_LIST).then(data => {
					success_fn(data);
				}).catch(message => {
					failure_fn(message);
				}).finally(() => {
					this.list_loading = false;
				});
			},
			add_item(item){
				this.$emit('success', item);
			}
			
		}
	}
</script>