<template>
	<!-- Add Voter Modal -->
		<div class="modal fade show" id="modal-default" tabindex="-1" style="display: block;" aria-modal="true">
			<div class="modal-dialog modal-dialog-centered">
				<div class='modal-content' v-if='add_running'>
					<div class="modal-body">
						<h5 class='text-info'>Adding Item</h5>
						Adding item to ticket...
					</div>
				</div>
				<div class="modal-content" v-if='!add_running'>
					<div class="modal-header">
						<h5 class="modal-title">Add Non-Inventory Item</h5><i class="modal-close zwicon-close pull-right display-4" @click='close'></i>
					</div>
					<div class="modal-body">
						<table id="data-table" class="table dataTable no-footer" role="grid" aria-describedby="data-table_info">
                            <tbody class="table-dark">
								<tr role="row">
                                    <th colspan="1"><label for="temp-item-category">Company</label></th>
                                    <td colspan="3">
                                        <div class="form-group mb-0">
                                            <select id="temp-item-company" class="form-control" v-model='user.company_id'>
                                                <option value="any" selected>-</option>
                                                <option v-for="company in company_list.list" v-bind:key="company" :value="company.company_id">{{company.name}}</option>
                                            </select>
                                        </div>
                                    </td>
                                </tr>
								<tr role="row">
                                    <th colspan="1"><label>Serial Number</label></th>
                                    <td colspan="3">
                                        <div class="form-group mb-0">
                                            <input :class="{'is-invalid': get_error('serial_no').length > 0}" class="form-control" v-model='user.serial_no' placeholder=''/>
										</div>
                                    </td>
                                </tr>
								<tr role="row">
                                    <th colspan="1"><label>Description</label></th>
                                    <td colspan="3">
                                        <div class="form-group mb-0">
                                            <input :class="{'is-invalid': get_error('description').length > 0}" class="form-control" v-model='user.description' placeholder=''/>
										</div>
                                    </td>
                                </tr>
								<tr role="row">
                                    <th colspan="1"><label>Comments</label></th>
                                    <td colspan="3">
                                        <div class="form-group mb-0">
                                            <input :class="{'is-invalid': get_error('comments').length > 0}" class="form-control" v-model='user.comments' placeholder=''/>
										</div>
                                    </td>
                                </tr>
								
                            </tbody>
                        </table>
						
					</div>
					<ErrorMessage :message='error' v-if='error.length > 0' />
					<div class="modal-footer">
						<button type="button" class="btn btn-primary" @click='action'>Add Item</button>
					</div>
				</div>
			</div>
		</div>
		<!-- End Add Voter Modal -->
</template>

<script>

	import PermissionService from "@/common/permission.service";
	import { mapGetters } from "vuex";
	import store from "@/store";
	import router from "@/router";

	import ErrorMessage from "@/components/General/ErrorMessage";
	import { LOAD_COMPANY_LIST } from "@/store/actions.type";
	import { SET_INVENTORY_LIST } from "../../../store/mutations.type";

	export default {
		name: 'AddNonInventoryItem',
		components: {
			ErrorMessage
		},
		props: {},
		data(){
			return {
				error: '',
				add_running: false,
				user: {
					company_id: '',
					comments: '',
					description: '',
					serial_no: ''
				},
				errors: {
					company_id: '',
					comments: '',
					description: '',
					serial_no: ''
				}
			};
		},
		beforeMount() {
			store.commit(SET_INVENTORY_LIST,{});
			this.load_company_list(function(){
                
			}, function(){
                
				router.replace({ name: 'inventory', params: { error: "An error occured while attempting to load company list." }});
			});
		},
		computed: {
			...mapGetters(['company_list', 'user', 'loading'])
		},
		methods: {
			getPermission(category, permission){
				return PermissionService.getPermission(category, permission);
			},
			get_error(key){
				if(this.errors[key] !== undefined) return this.errors[key];
				return '';
			},
			close(){
				if(this.add_running) return;
				this.$emit('close');
			},
			action(){
				this.validate_add_user();
				
			},
			load_company_list(success_fn, failure_fn = () => {}){
				this.list_loading = true;
				this.filter_error = "";
				
				store.dispatch(LOAD_COMPANY_LIST).then(data => {
					success_fn(data);
				}).catch(message => {
					failure_fn(message);
				}).finally(() => {
					this.list_loading = false;
				});
			},
			validate_add_user(){
				this.errors  = {
				};
				
				if(this.user.serial_no.length < 3){
					this.errors.serial_no = "Please enter serial #";
					return;
				}
				if(this.user.description.length < 3){
					this.errors.name = "Please enter item description";
					return;
				}
				this.add_user();
			},
			add_user(){
				this.error = '';
				this.$emit('success', this.user);
			}
		}
	}
</script>